import App from "@cospex/client/tracker/components/App";

export default function AppPage() {
  return (
    <App
      homeRoutes={["/", "/lokalisieren"]}
      headerSx={(isHomePage) =>
        isHomePage
          ? {
              overflow: "hidden",
              zIndex: 100,
              position: "absolute",
              width: "100%",
            }
          : undefined
      }
    />
  );
}
